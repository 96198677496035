import {
  getRequest,
  postRequest,
  urlTitle,
  monitorGateway,
  postDownLoad,
  getDownLoadT,
  getDownLoad
} from "@/utils/request";
import qs from "qs";
window.qs = qs;
// 设备管理管理
export const deviceApi = {
  // 设备管理列表
  queryUnitName: () => {
    return getRequest(`${urlTitle}/companyInfo/construction`);
  },
  // 获取回路信息
  queryCircuitName: (params) => {
    return getRequest(`${urlTitle}/device/info/getLoopIdAndName`,params);
  },
  // 设备管理列表
  queryDevice: (params) => {
    return getRequest(`${monitorGateway}/device/info/index`, params);
  },
  // 设备管理列表
  getExportDevice: (params) => {
    return getDownLoad(`${monitorGateway}/device/info/exportDeviceInfo`, params);
  },
  //数据源
  getOptions: (params) => {
    return getRequest(`${urlTitle}/station/info/getDictNameAndDictValue`, params);
  },
  //设备在线状态
  queryEquipmentInfo: (params) => {
    return postRequest(`${urlTitle}/device/info/queryEquipmentInfo`, params);
  },
  //设备统计图表
  classifyAndCount: (params) => {
    return postRequest(`${monitorGateway}/device/info/classifyAndCount`, params);
  },
  // 创建设备管理
  createDevice: (params) => {
    return postRequest(`${monitorGateway}/device/info/save`, params);
  },
  // 修改设备管理
  updateDevice: (params) => {
    return postRequest(`${monitorGateway}/device/info/update`, params);
  },
  // 删除设备管理
  deleteDevice: (id) => {
    return postRequest(`${monitorGateway}/device/info/delete/${id}`);
  },

  //计量点管理查询
  queryMeteringPointManagement: (params) => {
    return postRequest(`${monitorGateway}/metering-point-management/queryMeteringPointManagement`, params);
  },
  //创建计量点
  addForm: (params) => {
    return postRequest(`${monitorGateway}/metering-point-management/addMeteringPointManagement`, params);
  },
  //修改计量点
  updateForm: (params) => {
    return postRequest(`${monitorGateway}/metering-point-management/updateMeteringPointManagement`, params);
  },
  //删除计量点
  deleteItem: (params) => {
    return getRequest(`${monitorGateway}/metering-point-management/DeleteMeteringPointManagement`,params);
  },

  //国网计量统计
  //数据查询
  queryMeteringPointData: (params) => {
    return postRequest(`${monitorGateway}/metering-point-management/queryMeteringPointData`, params);
  },
  queryExport: (params) => {
    return postDownLoad(`${monitorGateway}/metering-point-management/exportQueryMeteringPointData`, params);
  },
  //计量点下拉框查询
  queryOptionsOne: (params) => {
    return postRequest(`${monitorGateway}/metering-point-management/queryMeteringPoint`, params,"blob");
  },
};
